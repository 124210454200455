import { styled } from '@/stitches.config';

export const ButtonStyled = styled('button', {
  outline: 'none',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '$space-2',
  textAlign: 'center',
  textDecoration: 'none',
  us: 'none',
  border: 0,
  cursor: 'pointer',
  br: '$rounded-1',
  bc: 'transparent',
  boxSizing: 'border-box',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    br: 'inherit',
    color: 'currentColor',
    bc: 'currentColor',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'currentColor',
    pe: 'none',
    transition:
      'border-color .33s ease, background-color .33s ease, color .33s ease, box-shadow .25s ease',
  },
  '& *': {
    transition: 'color .33s ease',
    pe: 'none',
  },
  '& i': {
    size: 20,
    minWidth: 20,
    ml: -4,
    '@xs': {
      size: 24,
      minWidth: 24,
    },
  },
  '& > * ~ i': {
    ml: 0,
    mr: -4,
  },
  variants: {
    variant: {
      primary: {
        color: '$primary',
        '& *': {
          color: '$white',
        },
        '@hover': {
          '&:hover': {
            '&::before': {
              color: '$gray1000',
            },
          },
        },
      },
      black: {
        color: '$gray1000',
        '& *': {
          color: '$white',
        },
        '@hover': {
          '&:hover': {
            '&::before': {
              color: '$gray900',
            },
          },
        },
      },
      white: {
        color: '$white',
        '& *': {
          color: '$gray1000',
        },
        '@hover': {
          '&:hover': {
            '&::before': {
              color: '$gray1000',
            },
            '& *': {
              color: '$white',
            },
          },
        },
      },
      gray: {
        color: '$gray300',
        '& *': {
          color: '$gray1000',
        },
        '@hover': {
          '&:hover': {
            '&::before': {
              color: '$gray1000',
            },
            '& *': {
              color: '$white',
            },
          },
        },
      },
      transparent: {
        color: '$white',
        br: '$rounded-0',
        '& *': {
          color: '$white',
        },
        '&::before': {
          content: 'none',
        },
      },
    },
    size: {
      sm: {
        py: 4,
        px: 16,
        minHeight: 40,
      },
      base: {
        py: 4,
        px: 16,
        minHeight: 44,
        '@2xs': {
          px: 24,
        },
      },
    },
    outline: {
      true: {
        bc: 'transparent',
        '&::before': {
          bc: 'transparent',
        },
      },
    },
    minWidth: {
      true: {
        '@md': {
          minWidth: 220,
        },
      },
    },
    full: {
      true: {
        '@maxlg': {
          flex: '0 0 50%',
          maxWidth: 240,
        },
        '@maxsm': {
          flex: '0 0 100%',
          maxWidth: '100%',
        },
      },
    },
    half: {
      true: {
        px: 12,
      },
    },
    iconLarge: {
      true: {
        '& i': {
          size: 48,
          minWidth: 48,
          '@md': {
            size: 56,
            minWidth: 56,
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      outline: 'true',
      variant: 'primary',
      css: {
        '& *': {
          color: '$primary',
        },
        '@hover': {
          '&:hover': {
            '&::before': {
              color: '$primary',
              bc: '$primary',
            },
            '& *': {
              color: '$white',
            },
          },
        },
      },
    },
    {
      outline: 'true',
      variant: 'black',
      css: {
        '& *': {
          color: '$gray1000',
        },
        '@hover': {
          '&:hover': {
            '&::before': {
              color: '$gray1000',
              bc: '$gray1000',
            },
            '& *': {
              color: '$white',
            },
          },
        },
      },
    },
    {
      outline: 'true',
      variant: 'white',
      css: {
        '& *': {
          color: '$white',
        },
        '@hover': {
          '&:hover': {
            '&::before': {
              color: '$white',
              bc: '$white',
            },
            '& *': {
              color: '$gray1000',
            },
          },
        },
      },
    },
    {
      size: 'sm',
      minWidth: 'true',
      css: {
        '@md': {
          minWidth: 144,
        },
      },
    },
    {
      variant: 'transparent',
      size: 'base',
      css: {
        p: 0,
        '@md': {
          columnGap: 12,
        },
        '& i': {
          ml: 0,
          size: 40,
          minWidth: 40,
          '@sm': {
            size: 48,
            minWidth: 48,
          },
          '@lg': {
            size: 56,
            minWidth: 56,
          },
        },
        '& span': {
          textShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 6px',
        },
      },
    },
    {
      variant: 'transparent',
      iconLarge: 'true',
      css: {
        '& i': {
          size: 48,
          minWidth: 48,
          '@md': {
            size: 56,
            minWidth: 56,
          },
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'black',
    size: 'base',
  },
});
