import { IMenu } from '@/types/layout/menu';
import { IButton } from '@/types/shared/button';

import {
  MUX_MENU,
  PICKUP_4DOOR_MENU,
  SPARK_MENU,
  VCROSS_MENU,
} from './product/cafe/lineup';

// LCV BUYING TOOLS
export const MENU_LCV_REGISTER: IMenu = {
  label: 'ចាប់អារម្មណ៍បើកបរសាកល្បង',
  description: 'ចុះឈ្មោះឥលូវនេះ',
  url: '/register',
  icon: 'steering-wheel',
};

export const MENU_LCV_CALCULATOR: IMenu = {
  label: 'គណនា​ការ​បង់​រំលស់',
  description: 'គណនាតម្លៃទិញរថយន្ត',
  url: '/calculator',
  icon: 'calculator',
};

export const MENU_LCV_ABOUT_CONTACT: IMenu = {
  label: 'ទំនាក់ទំនងមក​យើងខ្ញុំ',
  description: `ស្វែងរកតំណាងចែកចាយរថយន្ត ISUZU`,
  url: '/contact-us',
  icon: 'place',
};

export const MENU_LCV_DOWNLOAD_BROCHURE: IMenu = {
  label: 'ទាញយកខិត្តប័ណ្ណ',
  description: `ទាញយកខិតប័ណ្ណសម្រាប់រថយន្ត ISUZU ទាំងអស់`,
  url: '/download-brochure',
  icon: 'map',
};

export const MENU_LCV_BUYING_TOOLS: IMenu[] = [
  MENU_LCV_REGISTER,
  MENU_LCV_CALCULATOR,
  MENU_LCV_DOWNLOAD_BROCHURE,
  MENU_LCV_ABOUT_CONTACT,
];

// LCV MODEL
export const MENU_V_CROSS: IMenu = VCROSS_MENU;
export const MENU_4_DOOR: IMenu = PICKUP_4DOOR_MENU;
export const MENU_SPARK: IMenu = SPARK_MENU;
export const MENU_MU_X: IMenu = MUX_MENU;

export const MENU_LCV_MODEL_LABEL = `ម៉ូដែលរថយន្ត ISUZU`;
export const MENU_LCV_MODEL_PICKUP_LABEL = `រថយន្តភីកអាប់`;
export const MENU_LCV_MODEL_SUV_LABEL = `រថយន្តលក្ខណៈគ្រូសារ`;
export const MENU_LCV_MODEL_D_MAX_LABEL = `ອີຊູຊຸດີແມັກ`;
export const MENU_LCV_MODEL_MU_X_LABEL = `ມິວ-ເອັກ`;
export const MENU_LCV_MODEL_HEADER: IMenu = {
  label: MENU_LCV_MODEL_LABEL,
  url: '',
  layout: 2,
  submenu: [
    {
      url: '',
      label: MENU_LCV_MODEL_PICKUP_LABEL,
      submenu: [
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [MENU_V_CROSS],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [MENU_4_DOOR],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [MENU_SPARK],
        },
      ],
    },
    {
      url: '',
      label: MENU_LCV_MODEL_SUV_LABEL,
      submenu: [
        {
          url: '',
          label: MENU_LCV_MODEL_MU_X_LABEL,
          submenu: [MENU_MU_X],
        },
      ],
    },
  ],
};

export const MENU_LCV_MODEL_LCV_FOOTER: IMenu = {
  label: MENU_LCV_MODEL_LABEL,
  url: '',
  submenuArray: [
    {
      label: MENU_LCV_MODEL_PICKUP_LABEL,
      submenu: [MENU_V_CROSS, MENU_4_DOOR, MENU_SPARK],
    },
    {
      label: MENU_LCV_MODEL_SUV_LABEL,
      submenu: [MENU_MU_X],
    },
  ],
};

export const MENU_LCV_CALCULATOR_FOOTER: IMenu = {
  label: 'គណនាការបង់រំលស់',
  url: MENU_LCV_CALCULATOR.url,
};

export const MENU_LCV_REGISTER_FOOTER: IMenu = {
  label: 'បើកបរសាកល្បង',
  url: MENU_LCV_REGISTER.url,
};

export const MENU_LCV_DOWNLOAD_BROCHURE_FOOTER: IMenu = {
  label: 'ទាញយកខិត្តប័ណ្ណ',
  url: MENU_LCV_DOWNLOAD_BROCHURE.url,
};

export const MENU_LCV_ABOUT_CONTACT_FOOTER: IMenu = {
  label: MENU_LCV_ABOUT_CONTACT.label,
  url: MENU_LCV_ABOUT_CONTACT.url,
};

export const MENU_LCV_TEST_DRIVE_FOOTER: IMenu = {
  label: 'ចាប់អារម្មណ៍រថយន្ត ISUZU',
  url: '',
  submenu: [
    MENU_LCV_REGISTER_FOOTER,
    MENU_LCV_CALCULATOR_FOOTER,
    MENU_LCV_DOWNLOAD_BROCHURE_FOOTER,
    MENU_LCV_ABOUT_CONTACT_FOOTER,
  ],
};

// LCV SOCIAL
export const MENU_LCV_SOCIAL_FACEBOOK_DMAX: IMenu = {
  label: 'Facebook Channel',
  url: 'https://www.facebook.com/IsuzuCambodia',
  target: '_blank',
  icon: 'facebook',
};

export const MENU_LCV_SOCIAL_YOUTUBE: IMenu = {
  label: 'Youtube Channel',
  url: 'https://www.youtube.com/channel/UC-1DXEhDZtQFOBSqYWtElXg',
  target: '_blank',
  icon: 'youtube',
};

export const MENU_LCV_SOCIAL: IMenu = {
  label: 'Social Sharing',
  url: '',
  submenu: [MENU_LCV_SOCIAL_FACEBOOK_DMAX, MENU_LCV_SOCIAL_YOUTUBE],
};

export const MENU_LCV_PRIMARY_NAV: IMenu[] = [
  MENU_LCV_MODEL_HEADER,
  MENU_LCV_CALCULATOR,
  MENU_LCV_DOWNLOAD_BROCHURE,
  MENU_LCV_ABOUT_CONTACT,
];

export const COPYRIGHT = `Copyright © 2024 Tri Petch ISUZU Sales`;

export const REMARK =
  'ពណ៌ និងជម្រើសដែលអាចប្រើបានអាចប្រែប្រួលតាមប្រទេស។ រថយន្តដែលលក់ក្នុងប្រទេសកម្ពុជាជាប្រភេទចង្កូតឆ្វេង';

export const BUTTON_LCV_REGISTER: IButton = {
  label: 'ចាប់អារម្មណ៍',
  url: MENU_LCV_REGISTER.url,
  target: MENU_LCV_REGISTER.target,
  icon: MENU_LCV_REGISTER.icon,
};
