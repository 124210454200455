import React from 'react';

import { Text } from '@/components/shared/element/text';
import { styled } from '@/stitches.config';

interface IProps {
  lead?: string;
  title?: string;
  unit?: string;
  class?: string;
  css?: any;
  theme?: 'primary' | 'gray' | 'white';
}

const BadgeWrap = styled('div', {
  height: '20px',
  px: '6px',
  py: '10px',
  position: 'absolute',
  right: 0,
  top: -8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  us: 'none',
  transform: 'skew(-22deg, 0deg)',
  bc: '$primary',

  '*': {
    color: '$gray200',
  },
  span: {
    transform: 'skew(22deg, 0deg)',
  },
  '&::before': {
    bc: '$primary',
    content: '',
    width: 4,
    position: 'absolute',
    right: -5,
    height: 20,
  },
  '&::after': {
    bc: '$primary',
    content: '',
    width: 2,
    position: 'absolute',
    right: -8,
    height: 20,
  },
});

const View: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <BadgeWrap css={props.css}>
      <Text
        className="new-badge"
        variant="text-white"
        size="2xs"
        font="display"
      >
        รุ่นพิเศษ
      </Text>
    </BadgeWrap>
  );
};

View.defaultProps = {
  theme: 'primary',
};
View.displayName = 'SharedElementNewBadge';
export default View;
