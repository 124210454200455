import React from 'react';

import { styled } from '@/stitches.config';

interface IProps {
  children?: React.ReactNode;
}

const Hamburger = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  height: '100%',
  cursor: 'pointer',
  width: '22px',
  '& span': {
    width: '22px',
    height: '2px',
    display: 'block',
    bc: '$black',
    '&:not(:last-child)': {
      marginBottom: '6px',
    },
  },
});

const View: React.FunctionComponent<IProps> = () => {
  return (
    <Hamburger>
      <span />
      <span />
      <span />
    </Hamburger>
  );
};
View.displayName = 'SharedElementHamburger';
export default View;
