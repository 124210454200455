import { styled } from '@/stitches.config';

export const Overlay = styled('div', {
  '@lg': {
    position: 'absolute',
    width: '100%',
    height: 'var(--sticky-viewport-height)',
    top: 'var(--sticky-top)',
    left: 0,
    bc: '$backdrop70',
    zIndex: '-1',
    cursor: 'pointer',
    transitionProperty: 'opacity',
    transitionDuration: '1s',
    transitionTimingFunction: 'var(--transition-easing)',
    willChange: 'opacity',
  },
  '@maxlg': {
    display: 'none',
  },
});

export const LogoWrap = styled('div', {
  position: 'relative',
  height: '100%',
  '& a': {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
});

export const SecondaryNavOverlay = styled('div', {
  position: 'absolute',
  width: '100%',
  height: 'var(--sticky-viewport-height)',
  top: 'var(--sticky-top)',
  left: 0,
  bc: '$backdrop70',
  cursor: 'pointer',
  transitionProperty: 'opacity',
  transitionDuration: '1s',
  transitionTimingFunction: 'var(--transition-easing)',
  willChange: 'opacity',
  '@lg': {
    display: 'none',
  },
});

export const SecondaryNavOverlayInner = styled('div', {
  '@maxlg': {
    position: 'relative',
    width: '100%',
    height: '100%',
    bc: 'rgba(0, 0, 0, 0)',
    cursor: 'pointer',
  },
  '@lg': {
    display: 'none',
  },
});

export const SecondaryNavInner = styled('div', {
  '@lg': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '@maxlg': {
    height: '100%',
    ox: 'hidden',
    oy: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const SecondaryNavList = styled('nav', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  '@lg': {
    flexDirection: 'row',
  },
  '@maxlg': {
    bc: '$white',
  },
  '& .secondary-nav-link': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    columnGap: '$7',
    '@lg': {
      height: 'var(--secondary-nav-height)',
      columnGap: '$1',
      '&::after': {
        position: 'absolute',
        left: '50%',
        bottom: 0,
        borderTop: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid $white',
        transform: 'translateX(-50%)',
      },
      '& i, & span, & p': {
        transitionProperty: 'opacity',
      },
      '@hover': {
        '&:hover': {
          '& i, & > span, & > div > span, & p': {
            opacity: '0.7',
          },
        },
      },
      '&.is-current': {
        '&::after': {
          content: '""',
        },
      },
    },
    '@maxlg': {
      px: '$11',
      py: '$8',
      '&:not(:last-child)': {
        borderBottom: '1px solid $gray300',
      },
    },
    '@maxmd': {
      py: '$7',
    },
    '@maxsm': {
      px: '$7',
      py: '$6',
    },
  },
  variants: {
    size: {
      '1': {
        columnGap: '$6',
        '@2xl': {
          columnGap: '$7',
        },
        '@maxlg': {
          display: 'none',
        },
      },
      '2': {
        columnGap: '$4',
      },
    },
  },
  defaultVariants: {
    size: '1',
  },
});

export const SecondaryNav = styled('div', {
  '@lg': {
    position: 'relative',
    bc: '$gray800',
    height: 'var(--secondary-nav-height)',
  },
  '@maxlg': {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    height: 'var(--viewport-height)',
    pt: 'var(--header-height)',
    pe: 'none',
    opacity: '0',
    transform: 'translateY(-40px)',
    transitionProperty: 'opacity, transform',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
    '& > *': {
      height: '100%',
    },
    '&.is-buying-tools-open': {
      pe: 'all',
      opacity: '1',
      transform: 'translateY(0)',
      '& nav, & button': {
        pe: 'all',
      },
    },
  },
});

export const PrimaryNav = styled('div', {
  position: 'relative',
  height: 'var(--primary-nav-height)',
  bc: '$white',
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    bs: '$shadow100',
    pe: 'none',
    zIndex: '1',
    transitionProperty: 'opacity',
    transitionDelay: 'var(--transition-delay)',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
  },
  '&.is-subnav-open': {
    '@lg': {
      '&::after': {
        opacity: '1 !important',
      },
    },
  },
});

export const SubMenu = styled('div', {
  bc: '$white',
  '@maxlg': {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    // height: 'var(--viewport-height)',
    height: 'var(--menu-height)',
    zIndex: '2',
  },
  '@lg': {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: '100%',
    zIndex: '-1',
  },
});

export const SubMenuInner = styled('div', {
  position: 'relative',
  height: '100%',
});

export const SubMenuCar = styled('div', {
  '& .sub-menu-car-link': {
    '@hover': {
      '&:hover': {
        '& span': {
          color: '$primary',
        },
        '.new-badge': {
          color: '$white',
        },
      },
    },
  },
  variants: {
    size: {
      1: {
        '@lg': {
          display: 'flex',
          columnGap: '$3',
        },
        '@maxlg': {
          display: 'grid',
          rowGap: '$6',
          columnGap: '$6',
          '& > *': {
            display: 'block',
          },
        },
        '&.is-2-column': {
          '@maxlg': {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
          '@maxmd': {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        },
      },
      2: {
        '@maxlg': {
          flex: '0 0 100%',
          maxWidth: '100%',
        },
        '& .sub-menu-car-link': {
          '@maxlg': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            columnGap: '$6',
          },
        },
        '& .car-image': {
          position: 'relative',
          maxWidth: '200px',
          '@maxlg': {
            flex: '0 0 168px',
          },
          '@maxmd': {
            flex: '0 0 148px',
          },
        },
      },
    },
  },
});

export const SubMenuCarGroup = styled('div', {
  '@lg': {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '$6',
    columnGap: '$13',
  },
  '@maxlg': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    rowGap: '$6',
    columnGap: '$6',
    px: '$11',
    pb: '$6',
    '&.is-1-column': {
      gridTemplateColumns: 'repeat(1, 1fr)',
      [`& ${SubMenuCar}`]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
  },
  '@maxmd': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    '&.is-1-column': {
      gridTemplateColumns: 'repeat(1, 1fr)',

      [`& ${SubMenuCar}`]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  },
  '@maxsm': {
    px: '$6',
  },
});

export const SubMenuBack = styled('div', {
  position: 'relative',
  height: 'var(--primary-nav-height)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: '$1',
  px: '$6',
  borderBottom: '1px solid $gray300',
  cursor: 'pointer',
  '@sm': {
    px: '$11',
  },
  '@lg': {
    display: 'none',
  },
});

export const SubMenuBackIcon = styled('div', {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ml: '-8px',
});

export const MenuMobileTopInner = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: '$6',
});

export const MenuMobileTop = styled('div', {
  width: '100%',
  bc: '$white',
  borderBottom: '1px solid $gray300',
  variants: {
    variant: {
      cv: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: 'var(--primary-nav-height)',
        zIndex: '1',
        transform: 'translateZ(0)',
        // transition: 'transform .6s, opacity .6s',
        // willChange: 'transform, opacity',
        transitionProperty: 'transform',
        transitionDuration: '0.6s',
        transitionTimingFunction: 'var(--transition-easing)',
        willChange: 'transform',
        '@lg': {
          display: 'none',
        },
        [`& ${MenuMobileTopInner}`]: {
          '@sm': {
            px: '$11',
          },
          '@lg': {
            display: 'none',
          },
        },
        '&.is-subnav-open': {
          transform: 'translateX(-100%)',
          // opacity: '0',
        },
      },
      lcv: {
        position: 'relative',
        height: 60,
      },
    },
  },
});

export const MenuList = styled('ul', {
  variants: {
    variant: {
      cv: {
        '@lg': {
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '$6',
          height: '100%',
        },
        '@maxlg': {
          position: 'relative',
          width: '100%',
          pt: 'var(--header-height)',
          py: 60,
          height: 'var(--viewport-height)',
          ox: 'hidden',
          oy: 'auto',
          '-webkit-overflow-scrolling': 'touch',
          '&.is-subnav-open': {
            '& >li': {
              '& >a, & .menu-link': {
                transform: 'translateX(-100%)',
                // opacity: '0',
              },
            },
          },
        },
      },
      lcv: {
        height: 'calc(var(--viewport-height) - 60px)',
        ox: 'hidden',
        oy: 'auto',
        '-webkit-overflow-scrolling': 'touch',
      },
    },
  },
});

export const MenuItem = styled('li', {
  '& >a, & .menu-link': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  variants: {
    variant: {
      cv: {
        '& >a, & .menu-link': {
          px: '$6',
          '@sm': {
            px: '$11',
          },
          '@lg': {
            px: 0,
            columnGap: '$1',
          },
        },
        '@lg': {
          '& >a, & .menu-link': {
            height: '100%',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: 3,
              pe: 'none',
              bc: '$primary',
              zIndex: '1',
              transform: 'translateY(100%)',
              transitionProperty: 'transform',
              transitionDelay: 'var(--transition-delay)',
              transitionDuration: 'var(--transition-duration)',
              transitionTimingFunction: 'var(--transition-easing)',
            },
            '& i': {
              transitionProperty: 'transform',
              transitionDelay: 'var(--transition-delay)',
              transitionDuration: 'var(--transition-duration)',
              transitionTimingFunction: 'var(--transition-easing)',
            },
            '&.is-menu-open': {
              '&::before': {
                transform: 'translateY(0)',
              },
              '& i': {
                transform: 'rotate(180deg)',
              },
            },
            '@hover': {
              '&:hover': {
                '&::before': {
                  transform: 'translateY(0)',
                },
              },
            },
          },
        },
        '@maxlg': {
          '&:not(:last-child)': {
            borderBottom: '1px solid $gray300',
          },
          '& >a, & .menu-link': {
            height: '56px',
            justifyContent: 'space-between',
            transform: 'translateZ(0)',
            // transition: 'transform .6s, opacity .6s',
            // willChange: 'transform, opacity',
            transitionProperty: 'transform',
            transitionDuration: '0.6s',
            transitionTimingFunction: 'var(--transition-easing)',
            willChange: 'transform',
          },
        },
      },
      lcv: {
        borderBottom: '1px solid $gray300',
        '& >a, & .menu-link': {
          px: '$6',
          height: 60,
          justifyContent: 'space-between',
          transform: 'translateZ(0)',
          // transition: 'transform .6s, opacity .6s',
          // willChange: 'transform, opacity',
          transitionProperty: 'transform',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'var(--transition-easing)',
          willChange: 'transform',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 3,
            height: '100%',
            pe: 'none',
            bc: '$primary',
            zIndex: '1',
            transform: 'translateX(-100%)',
            transitionProperty: 'transform',
            transitionDelay: 'var(--transition-delay)',
            transitionDuration: 'var(--transition-duration)',
            transitionTimingFunction: 'var(--transition-easing)',
          },
          '@hover': {
            '&:hover': {
              '&::before': {
                transform: 'translateX(0)',
              },
            },
          },
        },
      },
    },
  },
});

export const MenuMobileClose = styled('div', {
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

export const MenuLCV = styled('li', {
  bc: '$gray300',
  position: 'fixed',
  width: '100%',
  bottom: 0,
  left: 0,
  '& >a': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'var(--primary-nav-height)',
    px: '$6',
    cursor: 'pointer',
    transform: 'translateZ(0)',
    // transition: 'transform .6s, opacity .6s',
    // willChange: 'transform, opacity',
    transitionProperty: 'transform',
    transitionDuration: '0.6s',
    transitionTimingFunction: 'var(--transition-easing)',
    willChange: 'transform',
    '@sm': {
      px: '$11',
    },
  },
  '@lg': {
    display: 'none',
  },
});

export const MainNav = styled('div', {
  '@lg': {
    height: '100%',
  },
  '@maxlg': {
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    // height: 'var(--viewport-height)',
    height: 'var(--menu-height)',
    bc: '$white',
    zIndex: 2,
    transform: 'translateX(100%)',
    transitionProperty: 'transform',
    transitionDuration: '0.6s',
    transitionTimingFunction: 'var(--transition-easing)',
    willChange: 'transform',
    '&.is-nav-open': {
      transform: 'translateX(0)',
    },
  },
});

export const CarMenuWrap = styled('div', {
  position: 'relative',
  zIndex: 3,
  '@lg': {
    py: '$9',
  },
  '@maxlg': {
    height: 'calc(var(--viewport-height) - var(--primary-nav-height))',
    ox: 'hidden',
    oy: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 3,
  },
});

export const CarMenuList = styled('ul', {
  position: 'relative',
  display: 'flex',
  columnGap: '$6',
  marginBottom: '$9',
  '@xl': {
    marginBottom: '$13',
  },
  '@maxlg': {
    display: 'none',
  },
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '1px',
    pe: 'none',
    bc: '$gray400',
  },
});

export const CarMenuItem = styled('li', {
  position: 'relative',
  paddingBottom: '5px',
  px: '$2',
  minWidth: '102px',
  textAlign: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 3,
    pe: 'none',
    bc: '$primary',
    zIndex: '1',
    transform: 'translateY(100%)',
    transitionProperty: 'transform',
    transitionDelay: 'var(--transition-delay)',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
  },
  '&.is-current': {
    '&::before': {
      transform: 'translateY(0)',
    },
    '& *': {
      fontFamily: '$bold',
    },
  },
  '@hover': {
    '&:hover': {
      '&::before': {
        transform: 'translateY(0)',
      },
    },
  },
});

export const SubMenuCarLCV = styled('div', {
  display: 'grid',
  '@lg': {
    rowGap: '$7',
  },
});

export const CarContentItem = styled('div', {
  position: 'relative',
  '@maxlg': {
    borderBottom: '1px solid $gray300',
    bc: '$gray200',
  },
  variants: {
    size: {
      1: {
        '@lg': {
          display: 'none',
          '&.is-current': {
            display: 'block',
          },
        },
      },
      2: {
        px: '$6',
        py: '$6',
        '@sm': {
          px: '$11',
        },
        '@lg': {
          px: 0,
          py: 0,
        },
        '&:nth-child(1)': {
          '@lg': {
            gridColumn: '1 / span 9',
          },
          '@xl': {
            gridColumn: '1 / span 8',
          },
          '&::before': {
            '@lg': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 'calc(100% + 12px)',
              width: 1,
              height: '100%',
              pe: 'none',
              bc: '$gray400',
            },
          },
          [`& ${SubMenuCarLCV}`]: {
            '@lg': {
              gridTemplateColumns: 'repeat(3, minmax(200px, 264px))',
            },
            '@xl': {},
          },
        },
        '&:nth-child(2)': {
          '@lg': {
            gridColumn: '10 / span 3',
          },
          '@xl': {
            gridColumn: '9 / span 4',
          },
          '& > *': {
            '@lg': {
              maxWidth: '200px',
              width: '100%',
              ml: 'auto',
            },
            '@xl': {
              mr: 'auto',
            },
          },
        },
      },
    },
  },
});

export const CarContentList = styled('div', {
  variants: {
    size: {
      1: {
        maxWidth: '1072px',
        marginInline: 'auto',
      },
      2: {
        '@lg': {
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: '$7',
          columnGap: '$6',
        },
      },
    },
  },
});

export const OtherMenuWrap = styled('div', {
  position: 'relative',
  '@maxlg': {
    height: 'calc(var(--viewport-height) - var(--primary-nav-height))',
    ox: 'hidden',
    oy: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
});

export const OtherMenuImage = styled('div', {
  '@lg': {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 'calc(100% + 48px)',
    height: '100%',
  },
  '@media (min-width: 1329px)': {
    width: 'calc( 100% + ((100vw - 1328px) / 2) + 48px )',
  },
  '@maxlg': {
    display: 'none',
  },
});

export const OtherMenuImageItem = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  height: '100%',
  opacity: '0',
  transitionProperty: 'opacity',
  transitionDelay: 'var(--transition-delay)',
  transitionDuration: 'var(--transition-duration)',
  transitionTimingFunction: 'var(--transition-easing)',
  '& > *': {
    height: '100% !important',
  },
  '&.is-current': {
    opacity: '1',
  },
});

export const OtherMenuList = styled('div', {
  '@lg': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '$5',
    py: '$13',
  },
});

export const OtherMenuItem = styled('div', {
  '@lg': {
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-flex',
    '& .other-menu-link': {
      position: 'relative',
      display: 'inline-grid',
      gridAutoFlow: 'column',
      columnGap: '$2',
      alignItems: 'center',
      transform: 'translateX(-11px)',
      transitionProperty: 'transform',
      transitionDelay: 'var(--transition-delay)',
      transitionDuration: 'var(--transition-duration)',
      transitionTimingFunction: 'var(--transition-easing)',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'relative',
        width: 3,
        height: 24,
        pe: 'none',
        bc: '$primary',
        zIndex: '1',
      },
    },
    '&.is-current': {
      '& .other-menu-link': {
        transform: 'translateX(0)',
      },
    },
  },
  '@maxlg': {
    borderBottom: '1px solid $gray300',
    '& .other-menu-link': {
      height: 'var(--primary-nav-height)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      px: '$11',
      bc: '$gray200',
    },
  },
  '@maxsm': {
    '& .other-menu-link': {
      px: '$6',
    },
  },
});

export const Close = styled('div', {
  position: 'absolute',
  top: '$3',
  right: '0',
  cursor: 'pointer',
  '@maxlg': {
    display: 'none',
  },
});

export const MenuPanel = styled('div', {
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  maxWidth: 414,
  height: 'var(--viewport-height)',
  bc: '$white',
  transitionProperty: 'transform',
  transitionDuration: '0.6s',
  transitionTimingFunction: 'var(--transition-easing)',
  willChange: 'transform',
  zIndex: '2',
  '@maxlg': {
    display: 'none',
  },
});

export const MenuPanelOverlay = styled('div', {
  position: 'fixed',
  inset: 0,
  bc: '$backdrop70',
  cursor: 'pointer',
  transitionProperty: 'opacity',
  transitionDelay: 'var(--transition-delay)',
  transitionDuration: '1s',
  transitionTimingFunction: 'var(--transition-easing)',
  willChange: 'opacity',
  '@maxlg': {
    display: 'none',
  },
});

export const Header = styled('header', {
  position: 'relative',
  width: '100%',
  bc: '$white',
  zIndex: '1000',
  '&.is-product-menu-open': {
    [`& ${PrimaryNav}`]: {
      '&::after': {
        opacity: '0',
      },
    },
  },
});
