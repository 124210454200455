import NextLink from 'next/link';

import { getIcon, Icon } from '@/components/shared/element/icons';
import { Text } from '@/components/shared/element/text';
import { ButtonStyled } from '@/components/shared/my24/button/styled';
import { styled } from '@/stitches.config';
import { IIcon } from '@/types/shared';
import { ITest, ITracking } from '@/types/tracking';

export const ButtonWrap = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  columnGap: 24,
  rowGap: 12,
  variants: {
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    display: {
      grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 16,
        '@xs': {
          columnGap: 24,
        },
        '@sm': {
          gridTemplateColumns: '240px 240px',
        },
        '& > *': {
          p: '10px 16px',
        },
      },
    },
  },
});

export interface ButtonProps {
  /**
   * Button contents
   */
  label?: string;
  /**
   * The button's size
   */
  size?: 'sm' | 'base';
  /**
   * The button's color
   */
  variant?: 'primary' | 'black' | 'white' | 'gray' | 'transparent';
  /**
   * removes background styling.
   */
  outline?: boolean;
  minWidth?: boolean;
  /**
   * Forces the button to occupy the full width of its container stretch
   */
  full?: boolean;
  half?: boolean;
  /**
   * Icon name
   */
  icon?: IIcon;
  /**
   * The icon that appears before the button text
   */
  leftIcon?: IIcon;
  /**
   * The icon that appears after the button text
   */
  rightIcon?: IIcon;
  iconLarge?: boolean;
  children?: React.ReactNode;
  /**
   * Custom class names to pass to the component
   */
  className?: string;
  /**
   * CSSProperties
   */
  css?: any;
  prefetch?: boolean;
  /**
   * A url the user will be navigated to when clicking the link. This also changes the tag to <a>.
   */
  href?: string;
  /**
   * If the url prop is set, this will open that link in a new tab.
   */
  target?: null | '_blank';
  /**
   * The function that is called when the button is "clicked" via cursor, touch, or keyboard
   */
  onClick?: () => void;
  /**
   * Human-readable selector used for writing tests
   */
  test?: ITest;
  /**
   * Human-readable selector used for writing custon event tracking.
   */
  tracking?: ITracking;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FunctionComponent<ButtonProps> = (
  props: ButtonProps
) => {
  const options = {
    size: props.size,
    variant: props.variant,
    outline: props.outline,
    minWidth: props.minWidth,
    full: props.full,
    half: props.half,
    iconLarge: props.iconLarge,
  };

  const _onClick = (e) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    props.onClick && props.onClick();
  };

  return (
    <>
      {props?.href ? (
        <NextLink href={props.href} passHref prefetch={props.prefetch}>
          <ButtonStyled
            as="a"
            css={props.css}
            className={props.className}
            {...options}
            aria-label={props.label}
            target={props.target}
            data-track={props?.tracking?.dataTrack}
            data-track-section={props?.tracking?.dataTrackSection}
            data-track-value={props?.tracking?.dataTrackValue}
            data-track-text={props?.tracking?.dataTrackText}
            data-track-url={props?.tracking?.dataTrackUrl}
            data-test={props?.test?.dataTest}
          >
            {((props?.icon && props?.icon !== 'arrow-forward') ||
              props.leftIcon) && (
              <Icon>{getIcon(props?.icon || props?.leftIcon)}</Icon>
            )}
            {props?.label && (
              <Text size="text-button-1" font="bold">
                {props?.label}
              </Text>
            )}
            {((props?.icon && props?.icon === 'arrow-forward') ||
              props?.rightIcon) && (
              <Icon>{getIcon(props?.icon || props?.rightIcon)}</Icon>
            )}
          </ButtonStyled>
        </NextLink>
      ) : (
        <ButtonStyled
          css={props.css}
          className={props.className}
          {...options}
          aria-label={props.label}
          data-track={props?.tracking?.dataTrack}
          data-track-section={props?.tracking?.dataTrackSection}
          data-track-value={props?.tracking?.dataTrackValue}
          data-track-text={props?.tracking?.dataTrackText}
          data-track-url={props?.tracking?.dataTrackUrl}
          data-test={props?.test?.dataTest}
          onClick={_onClick}
        >
          {((props?.icon && props?.icon !== 'arrow-forward') ||
            (props?.leftIcon && props?.leftIcon !== 'arrow-forward')) && (
            <Icon>{getIcon(props?.icon || props?.leftIcon)}</Icon>
          )}
          {props?.label && (
            <Text size="text-button-1" font="bold">
              {props?.label}
            </Text>
          )}
          {((props?.icon && props?.icon === 'arrow-forward') ||
            (props?.rightIcon && props?.rightIcon === 'arrow-forward')) && (
            <Icon>{getIcon(props?.icon || props?.rightIcon)}</Icon>
          )}
        </ButtonStyled>
      )}
    </>
  );
};
Button.displayName = 'SharedMY24Button';
Button.defaultProps = {
  prefetch: false,
  variant: 'black',
  size: 'base',
};
