import { ILineUpItem } from '@/components/sections/product/line-up';
import { IImage } from '@/types/shared';

interface IData {
  price: number;
  label: string;
  url: string;
  image: IImage;
  brochure: string;
  new?: boolean;
}

interface IMenu {
  label: string;
  description: string;
  url: string;
  image: IImage;
  brochure: string;
  new?: boolean;
}

export const V_CROSS_DATA: IData = {
  price: 49500,
  label: 'V-CROSS 4X4',
  url: '/isuzu-v-cross',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d45471d4ededc5e/6641b223d94eaff6c7d2c235/lineup-v-cross.png',
    alt: 'V-CROSS 4X4',
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc0c1c1ce99855437/665ecaae70d09b791ad62f63/cambodia-v-cross-brochure-2025.pdf',
};

export const PICKUP_4DOOR_DATA: IData = {
  price: 42500,
  label: 'HI-LANDER',
  url: '/isuzu-pick-up-4-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt742a1269e1dc7c66/6641b28f64db55ab72845f56/lineup-4-door.png',
    alt: 'HI-LANDER',
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0f57beb952cddf4d/665eca8a3c52974f2c0040d5/cambodia-4-doors-brochure-2025.pdf',
};

export const SPARK_DATA: IData = {
  price: 32800,
  label: 'SPARK',
  url: '/isuzu-spark',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt51d17c9e4dc80985/6641b26d8f19531bb4e5139c/lineup-spark.png',
    alt: 'SPARK',
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd0f8a139d9fd3a0a/65d2bf0623db7e3e32db25e2/spark-brochure-2024.pdf',
};

export const MUX_DATA: IData = {
  price: 64500,
  label: 'MU-X',
  url: '/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta88051ed0845f653/65d2bf04cce422ad7c037d9c/cambodia-lineup-mu-x.png',
    alt: 'mu-x',
  },
  // NOTE: permanent link
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5abc36980a44daca/cambodia-mu-x-brochure-2024.pdf',
};

export const VCROSS_MENU: IMenu = {
  label: V_CROSS_DATA.label,
  description: `តំលៃចាប់ពី USD ${V_CROSS_DATA.price.toLocaleString()}`,
  url: V_CROSS_DATA.url,
  image: V_CROSS_DATA.image,
  brochure: V_CROSS_DATA.brochure,
};

export const PICKUP_4DOOR_MENU: IMenu = {
  label: PICKUP_4DOOR_DATA.label,
  description: `តំលៃចាប់ពី USD ${PICKUP_4DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_4DOOR_DATA.url,
  image: PICKUP_4DOOR_DATA.image,
  brochure: PICKUP_4DOOR_DATA.brochure,
};

export const SPARK_MENU: IMenu = {
  label: SPARK_DATA.label,
  description: `តំលៃចាប់ពី USD ${SPARK_DATA.price.toLocaleString()}`,
  url: SPARK_DATA.url,
  image: SPARK_DATA.image,
  brochure: SPARK_DATA.brochure,
};

export const MUX_MENU: IMenu = {
  label: MUX_DATA.label,
  description: `តំលៃចាប់ពី USD ${MUX_DATA.price.toLocaleString()}`,
  url: MUX_DATA.url,
  image: MUX_DATA.image,
  brochure: MUX_DATA.brochure,
  // FOR SHOW A BADGE
};

const data: ILineUpItem[] = [
  {
    category: 'v-cross 4x4',
    models: {
      image: V_CROSS_DATA.image,
      price: V_CROSS_DATA.price,
      button: {
        label: V_CROSS_DATA.label,
        url: V_CROSS_DATA.url,
        icon: 'arrow-forward',
      },
    },
  },
  {
    category: 'pickup 4-door',
    models: {
      image: PICKUP_4DOOR_DATA.image,
      price: PICKUP_4DOOR_DATA.price,
      button: {
        label: PICKUP_4DOOR_DATA.label,
        url: PICKUP_4DOOR_DATA.url,
        icon: 'arrow-forward',
      },
    },
  },
  {
    category: 'spark',
    models: {
      image: SPARK_DATA.image,
      price: SPARK_DATA.price,
      button: {
        label: SPARK_DATA.label,
        url: SPARK_DATA.url,
        icon: 'arrow-forward',
      },
    },
  },
  {
    category: 'mu-x',
    models: {
      image: MUX_DATA.image,
      price: MUX_DATA.price,
      button: {
        label: MUX_DATA.label,
        url: MUX_DATA.url,
        icon: 'arrow-forward',
      },
    },
  },
];
export default data;
