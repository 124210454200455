import NextLink from 'next/link';
import React from 'react';

import { styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const StyledLink = styled('a', {
  color: 'inherit',
  textDecoration: 'none',
  variants: {
    clickable: {
      true: {
        position: 'absolute',
        inset: 0,
      },
    },
    underline: {
      true: {
        '& >*': {
          color: '$primary',
          textDecoration: 'underline 1px',
          'text-underline-position': 'from-font',
          transitionProperty: 'color',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease',
        },
        '@hover': {
          '&:hover': {
            '& >*': {
              color: '$primary100',
            },
          },
        },
      },
    },
  },
});

const StyledNoneLink = styled('div', {
  variants: {
    clickable: {
      true: {
        position: 'absolute',
        inset: 0,
      },
    },
    underline: {
      true: {
        '& >*': {
          color: '$primary',
          textDecoration: 'underline 1px',
          'text-underline-position': 'from-font',
          transitionProperty: 'color',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease',
        },
        '@hover': {
          '&:hover': {
            '& >*': {
              color: '$primary100',
            },
          },
        },
      },
    },
  },
});

type IProps = React.ComponentProps<typeof StyledLink> & {
  className?: string;
  title?: string;
  href?: string;
  target?: '_blank';
  prefetch?: boolean;
  onClick?: () => void;
  tracking?: ITracking;
  test?: ITest;
  clickable?: boolean;
  underline?: boolean;
  css?: any;
};

export const Link: React.FunctionComponent<IProps> = (props: IProps) => {
  const _onClick = (e) => {
    if (props.onClick) {
      if (e.cancelable) {
        e.preventDefault();
      }
      props.onClick();
    }
  };

  return (
    <>
      {props.href ? (
        <NextLink href={props.href} prefetch={props.prefetch}>
          <StyledLink
            underline={props.underline}
            clickable={props.clickable}
            className={props.className}
            href={props.href}
            target={props.target}
            onClick={_onClick}
            data-track={props.tracking && props.tracking.dataTrack}
            data-track-section={props?.tracking?.dataTrackSection}
            data-track-value={props.tracking && props.tracking.dataTrackValue}
            data-track-text={props.tracking && props.tracking.dataTrackText}
            data-track-url={props.tracking && props.tracking.dataTrackUrl}
            data-test={props.test && props.test.dataTest}
            data-test-tag={props.test && props.test.dataTestTag}
            data-test-update-date={props.test && props.test.dataTestUpdateDate}
            data-test-pin={props.test && props.test.dataTestPin}
            aria-label={props.title}
            css={props?.css}
          >
            {props.children}
          </StyledLink>
        </NextLink>
      ) : (
        <StyledNoneLink
          underline={props.underline}
          clickable={props.clickable}
          className={props.className}
          onClick={_onClick}
          data-track={props.tracking && props.tracking.dataTrack}
          data-track-section={props?.tracking?.dataTrackSection}
          data-track-value={props.tracking && props.tracking.dataTrackValue}
          data-track-text={props.tracking && props.tracking.dataTrackText}
          data-track-url={props.tracking && props.tracking.dataTrackUrl}
          data-test={props.test && props.test.dataTest}
          data-test-tag={props.test && props.test.dataTestTag}
          data-test-update-date={props.test && props.test.dataTestUpdateDate}
          data-test-pin={props.test && props.test.dataTestPin}
          // aria-label={props.title}
          css={props?.css}
        >
          {props.children}
        </StyledNoneLink>
      )}
    </>
  );
};

Link.defaultProps = {
  prefetch: false,
};
Link.displayName = 'SharedElementLink';
